<template>
  <div class="main-content q-pa-md">
    <Mural
      :breadcrumbs="breadcrumbs"
      route="knowledge"
      :query="{}"
      :count="`${numberParticipants} usuário(s)`"
    >
      <q-btn
        color="default-pink"
        rounded
        label="Adicionar Usuários"
        no-caps
        no-wrap
        v-if="!finishedCourse"
        class="no-shadow"
        :to="{
          name: 'add-users-in-class',
          query: {
            isFinished: finishedCourse,
            title: titleCourse,
            courseId: _courseId,
          },
        }"
      />
    </Mural>
    <main class="lesson-content q-my-lg">
      <div class="col-12 flex row q-pa-sm">
        <div class="form-input col-12 column">
          <q-table
            :rows="classTrail.students"
            :columns="columnsTable"
            row-key="value"
            :selection="!finishedCourse ? 'multiple' : 'none'"
            color="default-pink"
            v-model:selected="selected"
            class="no-shadow"
            :filter="filter"
          >
            <template v-slot:top-left>
              <q-btn
                unelevated
                rounded
                color="default-pink"
                label="Apagar"
                size="md"
                no-caps
                icon-right="delete"
                outline
                v-if="selected.length > 0 && !finishedCourse"
                @click="disabled(selected)"
              />
            </template>
            <template v-slot:top-right>
              <q-input
                borderless
                dense
                v-model="filter"
                placeholder="Pesquisar"
              >
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
          </q-table>
        </div>
      </div>
    </main>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
import TrailDataServices from "@/services/TrailDataServices";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import Mural from "@/components/Mural.vue";

import { ref, reactive, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useQuasar } from "quasar";

export default {
  name: "NewClass",
  components: {
    Mural,
    DefaultBtn,
  },
  setup() {
    let _trailDataServices = new TrailDataServices();
    let _route = new useRoute();
    let _router = new useRouter();
    let _courseId = _route.query.courseId;
    let titleCourse = _route.query.title;

    let $q = new useQuasar();

    let numberParticipants = ref(0);
    let breadcrumbs = ref(["Participantes do curso"]);
    let selected = ref([]);
    let filter = ref("");
    let loading = ref(false);
    let listUsers = ref([]);
    let group = ref(null);
    let columnsTable = ref([
      {
        name: "value",
        align: "left",
        label: "ID",
        field: "value",
        sortable: false,
      },
      {
        name: "label",
        required: true,
        label: "Nome",
        align: "left",
        field: (row) => row.label,
        sortable: true,
      },
      {
        name: "email",
        required: true,
        label: "Email/Matrícula",
        align: "left",
        field: (row) => row.email,
        sortable: true,
      },
      {
        name: "group",
        required: true,
        label: "Grupo",
        align: "left",
        field: (row) => row.group,
        sortable: true,
      },
    ]);
    let classTrail = reactive({
      company: null,
      student: null,
      students: [],
    });
    let finishedCourse = ref(false);

    onMounted(() => {
      if (_route.query.courseId == null) {
        _router.push({ name: "knowledge" });
        _alertOf("Parâmetro courseId inválido.");
      }
      _getStudentsInTrail();
    });

    async function _getStudentsInTrail() {
      loading.value = true;
      await _trailDataServices
        .getStudentsInTrail(_courseId)
        .then((response) => {
          response.data.map((item) => {
            classTrail.students.push({
              label: item.first_name + " " + item.last_name,
              value: item.user_id,
              company: item.company_id,
              email: item.email,
              group: item.group_name,
            });
          });
          finishedCourse.value = response.data[0].finished == 1 ? true : false;
          numberParticipants.value = response.data.length;
          loading.value = false;
        })

        .catch((e) => {
          loading.value = false;
        });
    }

    function disabled(student) {
      let message =
        student.length > 1
          ? "Deseja remover os estudantes selecionados?"
          : "Deseja remover o estudante selecionado?";
      $q.notify({
        message: message,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              removeAllStudentsSelected(student);
            },
          },
        ],
      });
    }

    function removeAllStudentsSelected(students) {
      const formData = new FormData();

      formData.append("courseId", _courseId);

      students.forEach((student) => {
        formData.append("userId", student.value);
      });

      _trailDataServices
        .deletedUsersOnCourse(formData)
        .then((response) => {
          if (response.data) {
            classTrail.students = classTrail.students.filter(
              (student) => !students.includes(student)
            );
            numberParticipants.value = classTrail.students.length;
            selected.value = [];
            _alertOf(`Turma modificada com sucesso`, "green-9");
          }
        })
        .catch((error) => {
          _alertOf(error.response.data.toString());
        });
    }

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    return {
      breadcrumbs,
      classTrail,
      finishedCourse,
      loading,
      columnsTable,
      disabled,
      group,
      selected,
      filter,
      _courseId,
      titleCourse,
      numberParticipants,
    };
  },
};
</script>

<style lang="scss" scoped>
.delete-btn {
  margin-left: 28px;
}

.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}

.main-content {
  width: 100%;
  height: 100%;

  .title-form {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.45;
  }

  .content-new-training {
    height: 68vh;

    .content-thumb-training {
      height: 100%;
      .thumb-training {
        border-radius: 10px;
        .q-img {
          border-radius: 10px;
        }
      }
      .btn-thumb-training {
        .q-btn {
          text-transform: none !important;
          border-radius: 8px;
        }
      }
      .label-title-menus {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #686868;
      }
    }
    .content-form-training {
      height: 100%;

      .form-input {
        .title-input-form {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          opacity: 0.45;
        }
      }
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
}
</style>
